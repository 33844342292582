import React from 'react'

import Page from './page'

export default function Test() {
  function content() {
    return <p>Hello</p>
  }

  return <Page content={content()} />
}
